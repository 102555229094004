import React, { useState, createContext, useContext } from "react"

export const ToastContext = createContext()

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([])

  return (
    <ToastContext.Provider value={{ toasts, setToasts }}>
      { children }
    </ToastContext.Provider>
  )
}

export function useToast() {
  const { toasts, setToasts } = useContext(ToastContext)

  return { toasts, setToasts }
}