import React, { useState, createContext, useContext } from "react"

export const SpinnerReactContext = createContext()

export default function SpinnerReactProvider({ children }) {
  const [spinnerReact, setSpinnerReact] = useState(false)

  return (
    <SpinnerReactContext.Provider value={{ spinnerReact, setSpinnerReact }}>
      { children }
    </SpinnerReactContext.Provider>
  )
}

export function useSpinnerReact() {
  const { spinnerReact, setSpinnerReact } = useContext(SpinnerReactContext)

  return { spinnerReact, setSpinnerReact }
}