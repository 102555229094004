export const sygnet = ['608 134', `
  <title>coreui react pro</title>
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   version="1.1"
   id="svg62"
   width="537"
   height="93"
   viewBox="0 0 537 93"
   sodipodi:docname="lo.svg"
   inkscape:version="1.0 (b51213c273, 2020-08-10)">
  <metadata
     id="metadata68">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs66" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1856"
     inkscape:window-height="1016"
     id="namedview64"
     showgrid="false"
     inkscape:zoom="3.1191806"
     inkscape:cx="268.5"
     inkscape:cy="46.5"
     inkscape:window-x="64"
     inkscape:window-y="27"
     inkscape:window-maximized="1"
     inkscape:current-layer="g70" />
  <g
     inkscape:groupmode="layer"
     inkscape:label="Image"
     id="g70">
    <image
       width="537"
       height="93"
       preserveAspectRatio="none"
       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAhkAAABdCAMAAAA/koTqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdC
AK7OHOkAAAMAUExURZeZmwAAAAICApean56cpSEhIQoKCWBgYhQUGpyboRYWFhcXFxoaG/Hx8RAQ
G////xQUFO3t7evr6xcXF+fn5+/v7/Pz8xsbG+np6fT19SIiI/r6+uDh4SMjI+Xl5R0dHCEhIyMj
IxoaHCEhImVxduLj4xUVF/j4+B4eISAgIR8fIfb29iMjIyIiIyIiIyIiJB8fISEhIl1dXyAgIiMi
IyQkJd7e33N0dyIiItra2/39/VBQV9zc3SIiI2dzeSMjJCIiJCIiIyIiIz8/QKSkqAwKDJyeoCMj
JCcnJpOVmCMjJLOztsjJzMbGyWVlZyUlJikpKygpKSIiI8HCxbq6vra2uqiprCIiIxMREry8wJGS
liQkJWlqbMTExyEhIm9vcr++wsvLznFyddHR1ICBhLi4vM7O0dTU12p2fSIiI1ZWWIeLjyIiIxcV
F05OTYuOkV5rcdfX2aalriUlJiIiI6+wtCMjJIuQlCEhInZ2eHx9gE9UViUlJlJSVCQkJZWXmSIi
I2NueVdmbHV3eXh5fZWYnv///4+OmHqDjVhZXCIiI4OHiT4/P8DBwyUlJqCgp/Dw8P///6Gho4aH
ixgYGSUlJhgXF6yssIKDhj09PnaEhwkJCaurrHN/hv7+/ujo6GZuc2xsb/Hx8XV1d+Tl5QcHCHKA
gzY2N9fX2EZLTfr6+kFBQeXl5RcXGAsLC+zs6xAQEJyfpCMjJGRkZwAAALKzvPz8/FNiZ0pKTDw8
PODg4AAAAP7+/vv7+////7u7vGBgYl9fYVJUVtnZ2v7+/vLy8iYmKD09QPf39ggHB05cZQgHB2Nk
ZkBAQXJ8f/Ly8igoKQMDBDo6PAUFBOfn6P///6qqqvDw8Hd7fgICAlhaXP///zMyMwQDBAICAoiI
iTc3OF5hZNra2fX19UJCRGtrbMLCwUNCRMTEw3N0dU5OT9ra2q+vsGxucKysrYyQkaytrUVFR4uK
jKqqq8jJykpKS+3t7ePj5Nvb25CQkPDw8PHx8eDg4ImKjaWmqEdwTMqr72wAAAEAdFJOU/8BAv//
BgT/Df8IDxL/C/8X//8V////Iv//Tf//gf8dN1opQP//Gv8xJy3/cZJtUjQ8/yBWqP//SP//Ef+e
/2KMeV8V/7H/hRr/dv////+wJqWZ/////0a+//+X//9E/////////////2n//3y5Gv////+siP9m
/2X+/9+2/5D/t///7f//vP///6L/0v/P//z6///OxcX//zP/xD3/xf3y/4c+/bb/1FXZ+yKJr6T9
1/++6jj/of//Sf1C5nb1UTPy1f20vd3/+5X/huDg/56eYD54auslzNZLx9fEbFU347KBzMNYtK5t
h+uue6CQvtKZfGLlg8jR4Ung67/y/ABFB4xZAAAgAElEQVR42ryYe0zTWRbH+f1mfrXb14/y6IMy
FIq0pXV4LCxqcXBUoowFQpaCaTKd1DCARpcdjB0fCZv4WGPWkMDMH4xm1A1DNgTc5R9Ao1RNZDLs
TjLGBwTHjJOYmBmz67ivyWb/2e455/76LmjrZr8/k1LTe3rvPZ/7Pec2K4TieJlMEBQajUauVKpU
9SgVe9UoFIKM5zkulLk4EMSHbxAUGQgGCYJMlnIKPA9TZ7FJmtVEwTAULIhfabJ8sl64wBeM4Uiy
/4G4V8pDWsqKgKHQIBSq3L7ssHJzVSqlXKMQaE6vAAZP8SlrQJ5SKUcpX04q/LiG2OBDXFJGKDAh
LZfiyleJpFRq5BpiHeFImWCiLCpcOs+FuNWYl9Ckj0eHRXLIcRF6MzoYkQPCmP5/sZElgaGAnKly
s81mvfVmrL5dQmlCuD2ZGgZPfgQpQ/Byt/WlqW3b0LvksDHxBxG5IN4oci4w/aJAEIpiMSOMXxJH
7iMICb6jYH6V+mCwnFPCU4xBo+U4LkqvnADOSDQywjSXpn+l1MuQQWAAF01mfZvFPrs4B1qUBH/B
u4fjISFD16A6QtwBeE1NNWZrbloCmsAyZGwhsngjwvrHAlubJicxcvaqwmBgggIP6xWwSHIJ4SCe
Mt50KCmCkDIbHHMYxiazJISfmZzA4OD5mDK97ZVUr0Kkqa6uAoUs1sJWkIzVU/5FZHC4UXJVtllf
7jjruO3KycnRhpXPpNMthWR8RmBQnZIjFeaatja93vHv7x9fWkGj+KRUMBh8fD/4aRQN9HCB3AKA
rplss5j1Z2/cH709i88LNBocX1KEaIsijs/qKWEGptYkqY+5jBzykYyGNIYGqciUmvqkMWhL4RQA
GHLpXFizpSd9Ma4RaxknpMxpkueltp5YI1zVfIgMmDuCYakqvjt9QZxwuVwTRpATZNMNARlDzhsh
gc/IMDB/SlW2tabNctZiefLsn9fVaqJNLUnHhF9oA5nwQcEsXGJU+C4YmUQYOJh2jb68DQJfurCo
U2sBasQ6H5FWR8KaTJGIoGGItngqCKxH0GD1FH0z22q2Ws1QVEFm+BPyoQQw4GuT9pHmEGYTBukT
xgDFAgODfUSvv/lZ5voWRaV9iU/OKR9t5VinpWJKMh52r5AqEyt5K5KBbYBGmWvWO6qq3yt48Hzf
mXPn9hzwebwof0ePU8vIUPAZgiFxYS+2P3necN6lm5I0pIUnKsJkATUR1nBE+G7BdT8kHd0IcORz
VfYns9d1BoMhGg5Da5MEX6JD4BeAFXHx9lIITyB2kazRYvVUf+NUguZBn99PNE2OqhnOAaGfTzFm
fjykYLw14Tzts8MLuoUYSau8Dhp+acGHZxUJZESwoJsllGD0LzPzmoTHyi4WKiW2W6zbWoGNLMky
mgCM0g2VWw8vXwYNHHI3HjjQeWCk8/V2kzZjz+BYA9NEXJROPz3dcNnd7nZ3uFEdKdWN8kjyMjoH
3T2i02aaEIkM6M05ZhjERdXd6Qd/HlYXjQ2hLdiMTiM4hZr5kgTEFD0ocJR8tdPktNkmJkyuxSDc
JajiymLr6Wx+WZGkPNDFvDxDXt7U41BCQaF9w8XpLQ773WG0u6gvuUR4hudDGo0mzK/9H67CwsKy
sGK+AWQg5bBCziwVTFWnJtMj746GF4dhH7hELqQ+vMlsnpzUAxJmy9mbK2syW66QyeT1QlInHkeG
Ai3DAmDUbt27bnkP6Pjr/gAkb8bdMdJiIzKwBU0XDdbZ5lprLI7i0g0Pnv+u4fKh7sbO1BrpHIlX
ICJ3o0+ESkNk4Dqkw2qtKUebu/b3ObHo6pDLZLM5YRvVEhVhLgCGROU7qWwBG6eWQtQLhMFw2KGe
fi7CeR6iVKG1XSwrGxsby0smg1GPcFZfK1gU4042Gt51cV4mD8etvjv9F93GuquFVzEaiaFxkSny
dWRt4WpL5VACQ6qxJqiGuA+JnbgSKytYXrlFb/ns+0vzFx7OraaHp2aD430CrxE0qdtrJEMmkGVU
AxgVW+4tNzSsXXv5Na+b6QAjwzbOp00GOoYcwNBb7FSmvmr4oKHbHYiRmz1gIvE20h0Wsw6/e7do
dBIZGuzMGRhwEO0I3CVx4699Lhdw4QQyEvwiJ7XU0MyAJlxzn4aw2lLJM7c5iqt3FTz4a+dHjVkd
vc27QT09uwsNkL4iw+OQIv5OgPUMwaBJbL3a09LuG+zq74Wnv7dQpMM9r6gnxwB2IO4PLkiwSY0G
kWeIziWm2kn9fkz7JbV7MI4ZEbpGHBlcmAuqWBa7xVH59F+LJiPuAMbOiYYNR6ZX2E+Ta272fh+s
X5HSNpAMKCbm8qrSgpKKLQffPEFk/MSPjgH/ImTIMiAD+xcAA8vUo2fn1zYc3+4lIGIVrSNJRITL
iTdKRj0ecUEh9UWluyof/eA/9kVAxE0PgyEZRn6EC7Bp8uqYdOQYTdjqTrgWEQ0qCxAQclxZ8mh5
zU/XDOzzs2kCsoV5RUVjQIYm1sJDUEs0quyacqS+9lHFa598cubjo41uZnWNRSLkUJzXQMcHlokb
UHLn2b6j779/5B3PSGAkkCz3zMwM7ABbtd/f3t7u87W0tDSDENGusjKoRDZAg1VVlkv6rYh1uFjU
quzFT7/+T74rf+pivAxTkeYOey2d1PsbbeIcdFtCypswkaHMrnFU76r9cOeOX/0xSkakmmgZGek2
GnQSCYyC2js/nl4LluFJBiLWIzwJRHj9+PiZZzgXxCBfr6AfEBA4zGMtAPenKx4ROgdnPBgRLgwS
FxFJ/61zMTTmlqSmhRJYu3nvN8snj5x896Me6G9Qvu7eHCAjB8mQxZMhR5oAjJLNFW8EtoPe8bQM
tsOQlu5NjAzltlyCh+J+N3DlysAXJ5sDM8mCtcNqGQ2IQk9PTxeoF9Tf378RhC+9dRiWeSeSwUn2
CQUbjLl4evrp161d4tXBDrd0AHFvPbCDPoyLUbt6+zfVFRnydUYdc6UF1/XbfcyKk8kQgH29o7Rg
a8X+t9/6w4k9a5GMQXfHDCaNyNACGUJ9mmTwAlYp3O+Ckg+/Wd7T0HD8kM/9ckCENcjU3SORISMy
mGMUQ9w7z84fO34ewUh0DAaGIaUYGmo8f4DGBfoZSmVuowRW7Ly3fGT9+tbtXd5BOrc+fz+UkzHt
KF+fQIbUncHq1u1/uzMLdMjTws66p5CRoWqy4kzfq9wKcb97d01r68DHzUlUeBgV7UQFYUFUdEWx
2LhpU11dHfSvzDOC4J08x0VvR6zDL1keODp1q7OxMxCgzY0Jy4JivFsbb0G4QoNWZ6RqtWCETlyW
wjWy8GirstvspZWb1+148ze//22EjA5M40gzVBPtkGlcUS/wfHpkKGDr4MAgGH871wBkjAym6CK8
MbcQP4NiEJ+wfO3tnigZcA3XyFVhMH48fWzPQC+2pwiGMbbHiAGDLhfsBpAXhQPQsBEao6F6Jc60
qhQSuGXHl8sn1x9p3d472EJqbu83lIFnjAKVsfUUyYADVb0L9m3/wbeIjF96d7MC4C8U8WI8jz9j
0A7s3fnGl0DGmtYrZ+I9A7EI1w7EgplFb6xbSFjUYT0hMlzondAbYH+EeEInXly94drTE1f23Qo0
Yt82w8Dw+6W4Em0Q8hZGwytSYZFW6mMWbOI8deJcCjKsQEbt4Z0Hf/6zX8SQQZ7R7MQmhshIq9Hg
w43trtrD6+4tfwVkfOD1wkZ0x9lDKovAw0rySfJ2idCIARlCvZxdCAgMMP7/cmr+QU2fdxz3eteG
U+LXhARC8wPBi5FGkAUT5Cvhh22KRRJ+KeJIRAgGjGiXA1xlhopynkGZXVGx1famd852HimT1WVb
q2fdiIi30dnrOcec7pje9Z/tz/2xu+7zeZ7n+803EUT5RNDD8CR5ntfz/rw/n+eBQfs4PgaGUJUo
RTBUiRFDI50nfv/auaXrIeuhBSf5lJDx/k1PJYlmZ0BFyHg5gQywGRvTVlF3VmsdJmQMeqktGDQS
Mo6svAwyn7cd4NH3Tsw8RUYMC0kOaaNBsCgqIlSQpYQ612hgmoHa+Qo1zimk8gGH/6glMuSpH95E
swgBA2bSIygGkwwYE4bDwWAq1BSNdDP3xXXa23majOy87Wv3lZXaqwQyPJ9Twe8WyFgaPzHPQQZI
Rj6blsnDCEaxc9vm+WjwCDg4xYAt2MbIAK+flJSckk/88q67jz7qa799AiSDmgxpVRKHhYF9j+se
ABqk3fpL/urL62F+YWtDPu2dCh8GMmTv2yp9ZEJ9zYUqWJZEMl4CzYAMBDpTUqHPsY9QMuq8pJ7x
OQ0CGWlQ8Gxdu6usqzEcT4awpyXWwuvzilQEBK1gWBhJ70PDY0WFO2QxHuuSTJJNjPODR+5Ia91w
N6315gRDlAwcTKNUqAkYIBv8J4loUDI2ZudtzdzV0TtQ1UDJ+JWHyX53c/oCycBhYSPinpp8Av6z
+Ed/qowREZcwRI1wIhISLNi2rQvwmBEJGbhL0BJkwmwfLYZkEsBMEwcG6IHKQMkgbBjigyQXgoaa
11LRWLKRLXJ5To2DkPGGrhkmEyXYVwSbFchYvH6pxGkRSczIpqsOUkvI2O0MeEk0MzIygAxMJkjc
yKcxMuI8wKzWQhALm1EnYIFLychYTI7WEIz8ahSMzK/vu4Mtg6ToIWBsjgODmQwqGYQMAyNDoU6l
aJj5bxLQQDLoR8S3P3AgRga1Agsl4yVaC6/ajtM9OfMhkHHoQx9KxA4pDJUBYY8EhKxKMysNumV0
AQOQkUrJSCa5BAsplIzivt9okQyxj0EchkbD05MWDK00eC6dNR5JzSInosFdWLo3P2s5qiYs8kVK
hsrHkr2XkbF0FjKylq/DabOPNBCfsbuyiH4cn4rDFTySdjkN5Q1UM6dGIKP5c4EKhoWkDmFqUcSc
BWKBOcTA3q9SqaVkwHsBw7WEVs1b1yAYftnm2cEQvUucZBgQDCW2OigaJjP3SbzXEMmAj6jPGThw
WiADsYBXQDKUCyMDrTuduYmZX6BmtHoH4/yD0+Pzvd3UMm/sly3i1Omp5mv/SH49GWcDtzekqHvt
fe2H+jhTHBhqEAwT5/1hgUsIt/hwufrfLOTkxhgaWmwt8n8jFQRsf1jkKkpGvcZLYL15M2AzGmcj
Y3FSCtT66zJh1QdGTlMymm2U8DaNSAZWL7A5QFaOEzKGvDuF8tT5NBZiDhEa6QYJFsA8aewjGXhy
mixUxACGJVi/hYBBC1UAI64EZmygAwWjESNDRAOCu/69tB2aQMbBGBm0cthEyTC9MBlCMtkAFU/4
PpJx6FhbXbyDcLY1RULzhyzawyvkJu5Cyt7k5BTiFSH1QYrqA5vxY05SlRDFUHGalmg0EvSzhzQi
0WiP0iSqhiqVoHHt3MpqHDS3IwfyKSVDERDESzcHGUuQDPx8jdaDjAyfkcpdQMObzUhGdfZrJEt1
1XYyMt42euL0PVCEa1Vkk4gF4wLEgvRKFfQeRKrZrOVJfc59mfQ681sUDKh6Ise2DbMWRiIY3kL0
nDR/IApytRI+UIwMOUPD9MW30kb3HGSccLISYlNzKh5sL4CMJKx4tmPFMxX+CshoP3Qs4JG4h8pK
j87pn18xmppa/O9Ccrh2IS1jmSgZFeUT9z9CMoY5uViWEMXQcIFo1CKzJIQMlgVDFo0Uimio1ESe
+W9SKBn6HOuBs5QMNcwnTWmMjKQ5yAA96GRkDHlVNAfOSYYsJBv0SRxhoJDIBLyURo1qUUTLU9zU
2KbFcxJyUGhCIkzqeg4WkJCRLPittSW5pW6XX7YNcongPSkYZKa9NmObc8c20raro9uy0quDqkyl
YmTAnqJk3OKPkDu/85HBSkskQ6n87IXJeAWKOkgm1L2MMDIKK8XAju/vvGfGDs8b9yD+/fjxP/+V
VZ2xLGUZNj9pioJkAgZ0EMlIjZlPjUkVDTX194jRT6PlvAvpAD6CEYPcKBQpZkLGn5cRU7s6Rsaw
vJA6QJ1tbjKIJpJVP8nI0NA9X6gEMkw8kpG1inDMsolM5g+G/PAIhfxCsH92p9M6REePYVVaV8gl
o4CzZ4eiIS4VZf/LJXuTyXkMZMCS1XfvuGSRRdviwaCC4dV563vOs10SE2FXwSIvr6UOFNFgonEL
L8DMS0Ylqy+3LJgMSMNZdFSrg5FR1MyOqXx4VBUInL347HBcdITDExOTd//+4K+/zqvOx+t84GpX
rEEJDx9GMm57OXWs9YldCq7Fcvj4qelT01fg6xT++SnE+Ph3YwUyikbEzQsn4AY5IeMPK0kJsVrf
KJKRWqQTAsnQzEVGSQIZ5DeKwBCYzZQMYoog4zg+ddPXZ+skrBXTtGDUqyAQGpgN4vwRyIQCPciS
LNIikpGyjDZXS3LvPjkv87t2SLwnU4xK8MNvuSyupoL9CdHktoT6fZxcSgaxGte+jR2PzUkGazts
aZYvhAxq0LJYGhbIsFEzRMnwqe85nnR2WiEGrNaaWb4P1NRMTTX2lnesLln72798kA2SkbIShQgq
xVL7SHs7lCa3bUCGpFzVmAKWGYdjJBZ79uxpOH365MnR0YctLopGdNCso2gY1Foio3ORIV6neD4y
2pSUJZuCDpuWnwYgv7ohF2uT424xr80SrugbvC1mODVKThaMf4bFFWzi3yPVe/LelRnE26I1GgPJ
eHfw6aLEqdvplp0vKChIzM/wo/4Cl/8t3qRhZDA0bvFXv5+XjObNtPcAZGgWSAYe05GJs178CSXD
SLo5Xq8vADtD8V7YbrfX1jY+I3J6S8v1Fbklma+uWJ4FkiH4z1x978D00eJi0AwlJ+ljKJRGbtgR
rqmx26cw7DUD1k5r1YGDV0A4xkdHz7uoaPTwVLNVBg1dQkjZK6RkWIbNNmMsdM9LhoKyZFOYKBlQ
tZLeS0fpVHi63zVbuN1uFyGjntMZGBXoOzmXBf9LqKrwmcF+cvERDDPtumNTZ3LGLQuFNm+JZRIK
hsejHg65+3taW4cksXuotbWngMT+UEsqr1TTa5GCaPzsnGhC5ySD9aQWTgYe4DLz1cDIMNA+kLfQ
AJMn/+9Eb2lXeVf5nKHX6zs6KnL3bVgLYLxG/CcexKxA19cYnj7aB2jcNvESMFQ6zS3rRGMOEEWi
t7c3p7HWbu2sOghJZXycoeG3qFVGhgb2T/mP0xLJ6DbZhJtXwIVO93xkBBREiww6hQmmmpBBeqDv
VJTn2MMzd8bi48bYDRLgiykZsXtdCjV34zvpc+9g3Pvj/65evXo943JGPjUZYGBAMoI9zrhMgmA0
893B/cfOHL5z6dL0pePwReLhw4djPx9qJWj0y9xmbYJoaGOiMR8ZO4EMjfIz8wuTgedNxHx12UUy
VOxMAGbaaEt/fLejo6yiYvUzIjc3950NmWvWARjVGRuTCRl529fsK+uaGkEy+voOabWsLIEZNeh0
/H8mAakOiDII+Etf3lWaUztQdQBlY3S8hYh5RKXUsQt3REQ/Tvsg7wcJZOiEnumLkMEuChpFMvKZ
6JchGmEHhuCiGhrOnj07ijHeZCFkGNhtP1yoVO7UxT2i4XKg4QK/9fWD3+dlZV/OF4bVo2TIgifq
NtFilYHh8QS4umDrma+uwG+OwMOx5/+snHtQVPcVx9swyAZYL7vcXe4+WGF3lmVZeb9XEAFHEUFe
KiqKIApBUENBxFaJTUnSaNtxBhOlsWPS2kmnjHZjnQShydSJ4jMTwWomU+Ok46jTMtOxM82k2s7Y
c87vd+8urxXbPeL6j5d7797PPef8fud7DgbVdesoqo6eP0Rs9KX1CWpZPMa9xq3bwaHz/JGxVCGj
Wfd/kdGDxRiZDIlvddoxFBsefLUwqbW7p8CfFcVmJBvrAQxnekkkrdSsKfW4I1VFZCx++Ycmhw8Y
dstv/3GzFIBq7SZrbU1KWljavnxJXX4nojHqOU9OY8ii5S7B7kNGkS8ZJrtcgsOrnSMZuTpeotEp
ZGAhFF5uQKO37saNKsqiwDo7Kyr2wSWRJ/taJoMLu3LxjMKdU51gmVV4TFU+JlyFSQXJkHBVUvqC
BcDCris/WBWStn396jW+kaSm0ay2hGy9fuo0HMkNz8nPeMQz+m4/BZTtSwWNLCFjaDBVkF8yGhh/
q4EMrfQ/kVEyjQwN2wDH3A59xhg8+NgMf5acbMypt6WmIBjY4hOO651avNKqHeQzXl5scinJJ7gB
6cnNVvAy7NjkZPgntqgnaWE7vLGZiAY9BEaGeQoZtklkrHTb+ZaHaHkOMtS8XqPDQg6kL4MLMFNM
RXFPYXTXkt7ERJZAVVdX5x/EBAhXTgoZImvukTq0AJjw8PSNap6GJcoJF8VVZ1RUVALbB07MBNK3
v1c2KZLUNIlmx4a9D69Uw4GK1eEZO1nK5fH8jdDY8qZo0qgVxSlktybc0/iuPzKwxFHTUNOwHn0G
LPsDQoaOVUVwi888rJu4G4uPz+jHcnJqbakxAEZUXGRYGLYRABlG2nfY8T76jFdfcuvlqruYZTdr
vhjLQJrAbDYb/pNjTIbHgnG+8wC8LhRO0oYskrz/zMiwDsRMJcMi1+ufhwy9yMkwMDJK4uJQXmoz
ZhS0LiwtbI9GgxSqqwtiXF1+ZsUBGddVl44yMtQGKU8Lv0Z4eA3+m5xxQdxNai1SwCBHhC7jDgaT
Y83bvIvV8poW0azJzb5xpbeLpWoYU6MxqvbWVR/spFOOMjT60lYImsnx5NbtUKZQmp0MVhIPIBkf
talxg5BqOkCG5tu755LPPcPGx8cHBgYqBwd3Fi8iHbayIU1kvPTqL9x6DVuVSPAEh7X3vsypB5ZS
4pmlxKTaao0ZRa2AxsEKfAohGE6G7GIWX5RieX8mMgRZxauZOxmH8vQil6ejkg7JiIhAiTsJTIt6
ILjJGVRpaXt0F+Y/4DWOyGRo6fV1aXO14K4EiIul3oSru6AoI7mWwMAsI54uN/HKdbidoVcat3kX
qwhGlmP3wWvR7RhXmVFULYSwSqdkcfUQoLE5RO3S+aJxmQprs5Nx9XtNDUwrAWSIASNDz7cVzcPw
c0I9NjJy9hkWH59gdcKiZH7w02AiI0rekOZk/NzNiiVaSbITGSOpMXhMJZrTabUmxMek1iYXUa0c
nIaHNhaHzGIWR2N2MuTuD0ve3MlwiUwLTmSYXo+D5CiyBLxGSio6r4zY2NgiZgXdrQsLo5dUI65H
PvOSgfURJEMShW++6oZEiwyOI1eIcTVqASxMrLjJBWc+DSuTVUNry1YrhZJNLUCyxfEXSLdaC5Rw
ject6E7CU9axuDrqyYbla9/2BkHri8Zl0y+fsvuclQwuomkIJBku5sDNzGkc/vY/Fy/Cjx+7cPEC
sw8vfPibeRhN+MPI52R89GM3TzIkzBSHpXtn2ZfHDV4vZ0I8SfKi0WkgGWkhaftFex6rW5lxGTAD
GWsEUZb6ExninMjIcvAuAWqdRDKwhZB1YqVCdKvNyeFxEigpaC0FNOAxcTJ2CxoS7Tp0zGd8c5eH
2hyIqhBWMd+yVqYviItL5/W/3vwDuOAdWlnG98PLy8obAYw8/b9uwqIOYDLm1GJIhRPzU/qkXKP9
GE5eEbS834DJQh0XQv2QsRjI+CmrlDc0a6i8EBAyHHyDgL2vZkkrtwnN1HCBlR7e84rdWoc/OR68
iEmZJ5PBFZ+UKAIZmK2iE2dWEoe9hSQNbF9SDZmG5/zGVasubTDkMsmt2YxC2alkbOkDMiT5YoCM
rLmToVFuAcnYiR3y4bgvBc4rJSYmVTZbvTED1aG9+RUH3pbJIIWVwaRmZNwfQRrIYmJiUtB9VtKt
laBcx1iUBAHyDO64D60vkwslZWX0zZr+OhaLazo6EA3PbAO3hRLo5b2IBqSh727dvHdjn+DtRqH6
yZ/D/PsMVjDfVN7QpEGfYQgIGW5e48xidafh4WHf0gSa3L+lNEp4WyX0f3pKqh38nbhHwshoE7jH
YGRY7sWDxyiJkGcsRESw3UJU+nQhGUeG9l+61KcxU8GbyNAbZiZDy78vgwhkDM+FjGXHzCatl229
A8lQ8TYhbMyG4IaWkJAQTzEulmLcvrd/4iXD5XIpZJwdTwEa2CEQHyG/wGZq7C1x8gJu5h0io6GR
V1Yby2lHQHoylmGsp7BqtTrR6KwpMTY4Z7ecjXu+PrY5+72NJh1ds8FlcuB34frDThUlGrP6DJTe
wdmQDHiWASKDb/5KZkWyRTkH/7BwPFCfx/8yKSc6FSSjOJz/ToWMq3sEDoYPGelxJd6ZGdSKAWv/
ntLldegzfvTKrjU6O5dCEBng9qeTsU0JvwYxd85k2N0axeGp1UhGGE0mCKMZCT4hLgpTDyyUYoxb
p5BB7Whuda5EZCRQtkkGR8FNRdLKnVpjSLZ749T7REZNM5diNDZjqB7WPfjynI1CT/oC1visxNV6
I6FB6Y3nWDaQwbRvJodL0rowoNy6PZ92NGbxGWtbuMCGkXEiMGQIyuavNEXK7W0hm9rKJxdRf6f/
+IXiyJLK6WQwJbAFX5Zh8Yv4P8JKZrBkJ/8BqxwYGD+HsvC6qn1veU4Kgr7Dp4UDXOhMZKzhCwVY
Koi5WXMlw+Lm+0bYr4xkFIfRcDDWpI6DgegTh4nQ8oI0CpPIMJDPwBtyP3YOer0fHUiYsaU7yYyq
Tn1GZGxqZlw0NoJ7g5tS/31kPMUaxVwMNxpg4mSa+0Jynz/znP9+9ubNuWWrVx5dGrRhT982LFsb
Pjnuh4zFChmwBgocGfAUvc98yvgMJm/ndR1vgy8Tb+okRsYiFKswMip8yGAqYEps7f/OsVqj+IvC
3xanNf7syN2xm9eunP71O9eBzk9bOnzIAJtOxpvbeDoICeHzkCHwqR0KGar5wcF8Op5KpQw7Yc0A
vGjcuYOR0UBjQvAjD2/J9Bi4CA/3Dv9RsRF0KpmM6MRMmYwmrgNuMdPqz3D/7IBzAW4a+0xVoZbo
SmqLYtn4W57fb92yZctr/W1tu++TGSkAACAASURBVN5YseLFNi8Zwc/wGazbAvfjAkXG1ObbSXT4
osHpYOZWMzLmFTMyqP2Bk/GGm1wGinjN+KClJxOfT7OJzycmJh48ePTo0T8FvajJa1EEl+asWcnQ
8Qt6PjKY1sFAbcWu1yOKVTQZ8YUpA9yogQiThR5KpomMoQ0trL5ds7JDA9+543FEMQ6i8s5vYx/Y
TSvLG3YwMsqbGBjNuUwrePl+QmV6RHgYG64TzE+NA0LioliBj5bwREb20mUffLAsKChoWdtKJEMP
ZKiC/fqMskZSUXRIASTDBwg9X3sYfJ2FQyGCDd5x0x+ByFB/HLwzYhoZK9wS8xhEBqBxkkUlCk44
IoEVEyl+4+soidJcyeAX5ZbmToaIAjMICQ41zr9AMsJCaVTj5KGP2GJN3V9MpEBkhOzfu+FYf3//
oUNt2U2oOXM8jkSuJk/XwiYTZbuvWiajrIVLS7HqABerv28dLAlXKQOh5NldxCPfIzu47wwnI4jZ
i7uOotQFyFCp/PiMo3m8M6YpV4JU8IQ6MGT4uAjWAuN1Ea7Jo5i8M0rcbkEtEhmhM5DxmiC7DE7G
MBlf8pxUzIIfsPZBlWwHNQzwNgYsGzimk7EaviVqYzG4pU/hy7Y8l8+gKt9hmYyZ+veo9VFRlRMZ
W9d+Z1kQNk73txAZx8Oxq4RzxYwprxXhSycnoxlYx2W4RTTnEhm/igIwQkN9x4ei4wqFs1LhiUTy
Z96ZREbQnrX4fPyTsfjqbnMzlzZnYSNPoMjQG5SBEA4+E8I7josGREyazSWbi5HxX+bOBybK+4zj
4B314O6o9w8OuR5CB6i3RTon5ODsRM8bNyziKJKeWDpEmFJ8C4qnrHjN6uUmdoKgwlanoWhig4Vg
2iVqbZdgzUzNqqvOGmM1m5vZkm0arS4z+/N7fr/33n+8/+4Au+cIOY0Hr+/7eZ/n+b2/5/k+qWJk
uMgKOGr8VfCEHiRcn487OJx0/guXUISMKva329STYYt+Jt/MkpEoToaFvfMJGR3eEPohoZBvd5MR
HWD+QT10oiVOrMmHwhceGSvWVb1U3b7aO+YLhpzoYHuGssSQnIGLcyvn0lUzr/PJSA56aTLQ75Uh
I3cdfVPhMoWpIcPPu9zAAA8EGTJAj0acDI2rld+1xMMCF1XSSMDql14b0YkO9l3iZKBVqz2aLFs9
hR5VZCT0FeaTz5hRMJEhg9eTICADAYbIQIftImSIdGuAtyGFL4SMTk2ACiILBMLUahM62p4hlOCI
yHRGu7RJkiIgQxMYK0YhkJCBLrUEGeuN0ZpE0vs3BWQ8/9qPegNcCzXXCUtXtxeJ2M6K/kx0cxvP
6ETIGMssFEheWTn97mbhCjia40T1r/JdKN+ZSEZRLRWO0Nbwir3Q41FFht/PfKbYZOtJmyQZupKU
pERRMuDEfh9OAiEjPAb3PI4I680onvQMWZalJj0lUZ3L9k8IyPBlwpJKgYxyaxMt6AF5xhSR8dsE
xIMfvtC3QDCB2t7Bqf7v4P+BtYaK9mKnBBmv/bI34I/J8CGQd/DVV15sEyMj5NXSFllrVUtGglab
jF7a7siKYpOpJ2OayCgTJQNb81o7Sjh6bkqSkUo6VTfgnis+GX4NpEfyZHzjJ7sTqEgEOajIQD8u
YJsaMnrJf502X6BjQGgN6CX8q766cmkyfuHzh9QywX1Hf4V2tyMyXCrIOK6ODPozLxSbTZPwGXDO
lcgoFSXjh1ZMhkEkEJH8BoS3cFVc10qBz/CHaTIMcmRQ3mRikXLjFJKRICCjQY3tHgQyDouT8VP+
z4zV0CJenIxAG3OVq9DJjo2M7sjazEmTkREXGeuciAwTJiNRVCCLKGJsKEWLE4HPCIddMZChjZRj
n2GbFjJ2qiJjoLYcLU3t+ZfFyQhNlgyTAhk/g5bEGH3G2kz7pMhwxEkG1YTIOCVJxowknR6r6JBT
eIHvM0L/B2RALk2FfFRAlTVUfXTlykfXb0KH2tdAxiuqydDwfYbxyZMRbMrFPkMvRUaqDBl+IMOm
kgwfkJHrPDK1ZPh7vcjWv7vnLQW7ffv2nTv37n21NfvkUE6eOjJ6e31K1uvjkWGaajKIzxiN22es
s0EZYrxkuE/JklHGnkI+GeGYyNBOCxmUO8NuthXvP9qyXNreWz4yQne1Xvry9Ps56dDvrExGb3PF
t5Vspt/HIyNDgQy1eUYyx2cgMoyTICNen7EOdBpiIaO+m+szTDH6jFxEhm4qyWjMHXe7XbdHVrH9
EBNs8apVF9fgrtat2bOfLcB7hxZFMnobOncpktE56J0WMrRcn2E2TYIMR5w+g4qVjLqdLBkal80U
k89w0GSIrZDjJQPlSZ7f3DmPWykkbceSJd97pvSb27IX1MyZn543y2BRJsO3UaKjmGsbeWSYp5wM
8BlW86jtSZHhHwszq1Y4s6NKZLB5Rm1fd5h5BuqCh4Ix+AxChh5vKMsbnsKUqMpnABn3PsW9q9L2
aikUQGfPrnlu7ryFs3DzogIZPqpTGYyZu6aHjGTWZ1Q9UTKCzNVtrrK2KpCRl47J2EHIGAyMaeIi
g/YZubZjBihck5//Fh1rmEjDKesz3J40bkOFeFfrhg3fXfodBEZBTnqeRQ0ZvVSnUo4x0WeMqiAj
VwUZWtZnVOXbzZPIM0yxkcHe91S1HZ1Z+0HZtQl50rUYnnQNFjHBRBNsy4fdhNh8Rq7x/qwyi55X
IzRhEhM7Z4dsJiqS8dWNrRtk21qX4rbWGuiywP3OBoMcGSGKag5QtdvlTYwMoxIZbjEydBZcrsyQ
waxMwGfkWydLRpp6MjrawsxOOiLD7bycKklG9Ok41nHsqOhjyPBR/ZkxkKGNtNtg89J4P32YTBWT
GwEHhj2HSjIuLV2ardDWOrsGaqBzsESCXpYMf7D6harW/rf3g53gvMCI7M65c7jtPA4y3ECGjqNv
RrYt55KqrJVARkjL8xlW+2TIKFRHRguuEN44yJDh16BVq9vxmQQZWIqTLiJF17qvro6JJRpfc3Ux
9NhcuanWZ5hgYxuRkY4HLUkOSDREZ7vCyLEkNWQY/wKNrbNl+1pP1pyE/sXK4TI8fFCODKrRZs0t
fnD09fcmWMsbLS1YducckUngk2FXIsOJyTCgq5z0VHSmJZzjsnnMFUJksFkG8RnTTQbsqLXcqsXy
TpyQ4C5Ep/ZfCw2iO2pJqcxeK8hDDVQE2U8mN6PkCOUZH1Za9Op8hgmqHkx//9P9+x8r2zFkBw+m
wvwwpVXruPXRtmvXrp2WNdzBOB9cBhQ16mXyjECv2T3eZHowsqqriwjuEFt8satrESu7c65ISEam
KBlBlowXaTI26VOZHBzKMbGWB8nlWvb9hxNLsM9w2ieRgcqSoWfI2Nyyh6jC+ZkLTFXB0dovp5SI
TB3h1meAJG59B/OYC7LXRhfoTV/HVT8qfEa7GasRm5mGsYzolMRoeTe3Mmvv3r3ozaHfz0jRyfuM
cXT81sePHj58eBVeIvZHYoew4al1hy7r8G0qSkZwNXL6raYH53fsoAV3aNmdJVh2h5FWESHDLkJG
M0NGuB+iieOTvE0WvY6Z1qvTW8qwyiDZtXy3nesytCwZuinPM1L00cqdH6w8sQ/LgaBEg00j7W6P
23EIWoEFz5+Io8uqxDhvuThyu2MnFwxNsyMN0uaz+KBV+Ixql5mtfeHWcEZL9hg4SGEWyPnmn/0v
niQlRUbzi3Zc/FHIG2XHaT5hJ0mZ2YEUVtuZlGEouGbJeJ5Hxri71fzgU9AFKOUYSARs2bGmazNp
5hQjwzaRjAGGjORkB4i5jg7lbYJxm9FR4jAeji62XbTy7X/3a7XCPONw/GSY5clg6kBP7MNKMbsq
2EscQeHE48YC+vQoYSYAkrpzuiW2a/mbAzwwwhGzDZGR9o5eFRnJGu1qb5u3LWrettWy1t1enGY0
ZlwvATIqJfdNAg7r+DhyG+NREwqOnxIYNDQ6TWdSh4kYkxQZ4/Z/3gDBnW2sbQWBgNJntnwLVHdo
/QwpMqJKsfVFgzvbWAewAhz0kas5WcOcUeLQ4UQ3OC06euEDrZbvM1w0GanxkVGoSAZ8cvMbB/6K
w0lngA0n6+Gu84x+nAT1xdzaczKVdx6ZZLNm5EKomwuGJjCWAfdk/jGDOjKSNYKCqECEfhGL8L78
zZqnoZQOkVGCy+al9loTmgQziiR6no1E4TYjAwrETWd0wzlzZcmwProEy50FrIHqDrSAb1kDWhWy
ZGSzZNTXdzOXOax1oqzOPXp1KB3PWiZNkdBkRjc4Lf/ig26twGdgMs7i0qrYyWiUJwOEhwBjULW+
NYgTjTpmdRIOOArRjVb4+GBSCY5+TIuLjkwRJFKD57/o54ORTLVnOtG1uPJrOhApkiEwLef7ROv2
k/rj68vQYciQkRCgemU8Tz87qZN+77XagQy9MhnXQHHnWdZefrkGq+68umQNCBLsmRhNrIQMWncc
kdFRX1RUG/ayHqDcDP7MafqEP6z8D38Gu3u3XeAxsM9wIjJ+ZRAt1FVDhsMhRQYzIwF6RvaTRGPm
xhD7sKvdDKHa8fhYiqVkGdPkZiCt0vPngGLE5+f/0a4RWF+jC0rpz2ZtMqRK95tIkyFv2kA3kJFx
fRM6EjkyEBvBYJBSZfDPBqxmIMMwnCMdTVBWO+58ePp3z82JKu7QsjugSABTiRavPHFAkowCRpH+
xw1ABptooAtdbXdAqMt1MtkQItVus+U/XWxs7NcmC8AAn+G0Hk57Bz8DiZMMozgZ5HLRBXuIDBJO
OE4DKv4wGqbPKnUWNvyVDZMAuGDpjc/v3e0XgqEJWkEpJfPyrE2GlK+VjNgsYDc7nabjKsgo+DlW
FGAMhCvYNl9RMg4DGdAvT2b/vNVcV1RUX+flRIhIm8ea63GP04kRJEJHDo+OHml9aYyKaIQeI0pG
xjF9SWrSjHjI8EiTkQLDzdBZwA80Thy4RTSrO4NsPAm6MRoe++PjN7NmcePf/IL3r3154293270T
wIh050Ng/7Agy4LTjGkjwzC1ZFBAhu24pXK+PBm5D3Mq0xf+j7rzD4rquuI4ZlF+yi6wyKL8UGQB
MfwUFDEg4iosNquxVhQxO+JWi4v8EsWqC1XcrmDrGpZfapXQ0jgOA0p0psZYk+wkMvFHFDXTiJpW
a9KqiFP7j02TSe85977dB7sP3FX/6MFkmLjvzcvbzz333Hff+X7DInMsASoJKNCNLRYrjtqQEcWR
QfuPF2/ch2KZvHUrCaV+0Xq5tSqShQbPXreyxdVg0LsaXe2Q8eaE0DbZuY/9vX3sOlY6nzN+HEMK
jRxsb3udCoKg8JhbXZLRsuGqzC9naz+p79+v3PsnFwMkBr/9/tlKWy7IZPITmACDroSV+LuPfTVk
gIHwrRzv+f4vm4yO5yIjsJOvDcBmV5SogXnZHhmhhIzAA9GT0XEDmvpqQX9ZrTbyv3MPg8FV0YI+
ZS0tFUZXaEfR610FwvBGADkryGe8EjLo4oQVGrQGddtQuNwynyirVsvkTEdRbtEoJJNfWlpQ+SK9
QW8Lht7g6yuV+Z37BvoeXx0Z4291vmwypEiGBLv8RySDigP48B5JgWksNb4gU8U++zkDyMDVHDT1
PTSBaHu7pmLo1+2h1+thgaZX6vUi15HCQ+/rJ22LuuFf4m7/dacXI8MdzKag0IDphEsabrq91lJD
r1WslZLEhhYtZNEv75AdPy7rWLuyodRUZYcLkYf251Fk/ou6Mq0zx8vH87VXQEaDOCBgf9CtTv/5
nNbFSyTjXqQNGXuHkhH8maSENYFzi3gqSJCXvKU405YMF+1mSgZo4LBla1OjZjfMJ6rSClenwmhY
ND20zfdWTBjdgXCcDJlgnUE7A/AhIm1BtCQNfqkhEuWXevxszWyUww0Onlm+bvWylp78Um2+XmQP
DFFNFUkZbQFnp4aHebt7jqDT9UI549WQIZOPSkZ5OZCBG1+W7vFxY5k5E17JJhsySjdPp2SEU08B
7BF/aFJjqaF0Eg1DsG9oW9o9WP+9fDLAdpxT3YGrbTrxkKKRpMvnoUHYKC0FEGqUuAbUVuXbZYJV
GT+dLpW2iQdilkRye3H2Hfb+X8jYaUuG/3z3sbz7iW1ZFg0nUl4W2iUjbAmUqSg5gmJe8EjDaTQM
y4JCOyYciZmRAwXoSyeDW7fCCwCQNPadUBUwNPhZg731q6zRj0AE9wzEGCSVdkz6LOFAoMTLjoaw
1ZVzl1YhcgoNgw0ZFq8Cm+7FFyCD0+nKKDNxtHFkeA/t6GRSFZxrgjAZoPM2kekUNZ830WZrskBR
OE6GYrysg9Ry4Z0SgQL0OciYOVOIDMuOKSudSKVxns0nSbrWCg+R45Ff5RcQ2hF062bCElyz8nTH
wck32erkm7Erv0XhVHgsH0aG1d+EkKFY6GwoCRlyjgz6GhXNGdoGEW0hqWmREjJmAhme4wT8NMhR
TfbJQDNmdJJB1dBDl7UMjd2uFUbHiowWkyLqjEw8EE4Ss0ABKkBGQ34NhMi0TkrICBAkg75/Q30G
V9XvIPMJV2q46QqNRofBUGrJUio04Nw3pw4E5ljW2ZzHczRuzZJBhTmjbE+V1iT4o7X8MzyqNFU8
MkD4B1bd1C0rI+ILZZVzAc9ApVYyqFANdb4Bj5MMEr/I2LUmGMmYY48MKm+zKsU+Gb6/llAjEhQ3
S19M5pPtDI32Ao1rxfMnDkWFUav77QSpeDAa77IjZGiWM8i160AaTJAMGMuWpIEixPtOXFZzaCRV
VfQ4xAUpRdZPJ+X9O09PHQgnKYPb6WG+8NzwTul6N6MM0NjlXFyISDvuezzoiGQ+NbbCne23KBkZ
EWXOxq4LwX5y+YQbhAx0m8gm5/wNkkHAYHHhTfl6QobXsO4HRgadKd7quo1kqIeS4XdEAnYTMdQy
hA7D7aVMzKOgfXeph3W3ecQw1rQWqgr2TxAP4l32snlHwq7bK5czFtKcUdOKFo7CZEAN6o0CcFlx
IdVU9dWCRp2uXV9hFD1HcUGjZ3lVQ0BoMAHjw2sxfJhdLKsguHXV9V21B987fJj8cS7K9uBLGjfA
jJmSQa79atdtbcSLREbEnzrazvh9+TFZQlArIDjnnrKd/LiwRr6+fOanPrZkcG+4gU33bXVdkpuu
oIFtHruYNgeR2eRT7xIJPBBDhU4chk0nLre2zuLyRvus3Zq9ptGidXch+aRukfgsu8tCkwmfDLBq
QTLcktBgsa5uw+nyUcgYR4V3UKUPCiOKhorrptGp8xUIh340PnqMFT1uhXK/M0HnHnx4LXoaL2Uw
MmhuKoLx0m82b3cqzGZzf3//3UePHv3+I0mJP33AlEcH4YJLew86H+8d/CIiKi3oy4/COgOnwcCG
C+2+bzi8hwX55fDhnWAush86poZ8HWxWBu1NUsp379C0FxaqNOiUvbmlpWXhavH0SWd/JykBhU50
AIijMvFNhxpPmrZxuj+F6vb2glGivV1NVjRJLuLv+27iXfayvwXPIwMXhCT9qfntU0k6JMNXkAyW
NNDHtwhKDUTjb2yFgmyotimNigqFsUcwjEYySXpoVb2aqP3ytO/ibn4wOXyGhJflgAy64w9bC8WV
sZ93dy/oXuBwdHVbjL6ipwWisxVn4A6DcMF98zFnwnwMeLt79+7g4MA18J6CQoNunG4iDJvh783s
Iw+ePv33HyTDHy4hGZHMSp5cST87qB8wvvv4MRx1IJJ6tJHxgYrP1UvBkqb50NHavfShlwMh+suD
r/EuC1cZVlxTafq7P4QMN105rUB95owVIAMXXDCfTKTDeTGa+tS1qyy9Vjqdm3qbKb9GLxRKrWZW
wYbe3mXitvHib/u+/iARnmV4eY7lO/nirAV+pVsIGuj25URcXVVdnZ1Ljb46c7y93L04B/dccqfT
CThg9OZodFudfKNjloC1B04nZKRUL+1mjnVWJk+FEyZ9bMigDvWplqO4wz6/ePF6HzlzDFlfgqC3
BIfhlK1AELwcCGyc1Jg0jI7hnqe8/4K//nLbttYfvnp8py8+D++yu6dQymBkTEMRYdCkVw8xKz9d
Lp39RvDZv3q5C5xgDD7TgJw8FVXt8X21pmZIGypr6tmgg9hgP8jfkGmrt1U2yVf83eO+5NREkuW8
+YWRy49jsARFw2CCBnP7io0dqeHUbg9qZUhxbtHc5FRoToZ3afHSE+DSc0MqY4E3QpwD0OGnLT3P
4OQbGJaD1h558eBKlkkYhk+A2W9mCGNyRs7w3U06vmagqnJcLh51FQzruKPAIxgGCyiDS/DVToIG
yZ3p+L75ikPNRy+drCVFhMYiE8UsxUFBzBom7Q+1l776R+b1O3Pj8S4Ll59W/UUUEc6Ore8yz+LN
Sqo66SS/gKg/e88RIoOggVqz+KVlFc1DyxIwV2p8yGdjpFCpkk73qteI09L+++ROX/JE6A/19+J3
1AAZsLUAlhl58VN4bl+ORTFYM20Fo6+EcKi93H18qKU9+BER3rIrqyEIQrHVI0Ss9V+AGxi6Fs+j
Tr4x4E6REwmLy9TkuUW5xWAMBj+V2cBkFsUeSqihZGCTBeREuJLc4pBscHGtzuRIhgsOk4DZBN17
w7PHzQO7xnrwR9y48Y+HVkAv09FjZrBM/WQ7uKfW1ta+e5L8ji6qZvPtX+1YnJJOMte8LVkIxgwJ
zCVCKYM9mcWyHyre+q7bZhY4xz0dHBj4TxhOR0JkoKIohwbkQmqC1Xj+8idQio7S9p2kO917unW9
WDzp2ZOLd8hNSEwIp5dsvXcujD/cqgY3FvBxAjNLx6II7DWhBXVqQswSGC8+nlRUP8HKG3N6G4m7
4iG/g+kq4Q2cfN8GX05obUUld/LlZW2J484JbdFbyRfCmBxWtb2G/3sScEBMjQcfRnrUPHpmagWb
g/rePnQCJGgAzOg5Bm6F4J+J7UwWo1XwPt3I0/4g81N9+tLYzOxcktzisT0br0NYVYITEQZ3jdfB
wKq7u4sWa1QvhMyd9AmqEFtjUBDYP4xL9SGV1K7v/ebGxssnCwqh4lDZD5hnkmbVrBWL33n25F8X
r8cxMIZfMpKBgtSB4dGTp+al4ju1NLKe+88UfCuXcJEYjVro7EZDviNoWHhjxBUJ/tjyho6t5OuL
RsNWb6a3PxXPuRVPST8zMS8Rsoq3bQ633MMEPGoudxSYd05MTZyMJLtTsXe44nAuedKJdenS9PT6
+pT6FGq1uskaKSj+cRWns2rUcyhChhEMvI4xgmSwREZrJjbZwrk4vRAyw8FGrbAuBaJBx8nbMP+B
HSydAN9f0fy/zq6etZErinoEMkISg0DFgMBoVwYhiY0tMyncKd2qFFtErtwb1p1ZtgqB7UTq1Gny
C/ILQtrkD+RPpNkiTVDuOee+mdForI+5ViOj92aYd+aeM9K79/z8019/fvnl9+r47ct/33/949+v
//y9MRS++4RkWwEMQwaERpu3y3SWuX2dG+v147X7Zw7RJB+/f8MQT3h7HAzonRogd2qgtHUAvClj
dOjFsEzxi7mt8uC2UP1q2BmtwAp7so91Fqhu06jb21A0OxCS/QZvsvDMoJHijO+BjRsQ6/uXl+9E
brBZda9V+aiK8H54AZ3RNPPtYC3TYWwEOAAMl6DQxTBGekeK+0amrOgXMkC/EOygOdCxhKuWp9An
+RVvaNf74/Pzw68PVfH8YOkuU/amsz4zyRkw+E1G8dIRGYLGcpisRjNsqa0VMPqaJrizW7zQMlwY
BrxhE/f5gV3gd1x0PO7ELYmBZBQwDPM4YlIljgJGVP4yOYB0NucohUYJyW1WaQtC2GBNbHyLRGfs
Q/q5KjitvtBGNeirj2j1YIkLGeiaxdl93hqHgMFdFh3XYW8X8KH+mPULEXcqZRyYI4fGHE5Hixtg
g+V4G9g5GweaSsIr/0NYrttsIL/fXxn5iU4DlncOd7HNoNE1fZemCb0sz4/pdIoNmL0uqcS9Gugg
hk26sJeT09tZYJu/MbjN6O7TVfsOVk2MU278ncwVtO5MIEPoMxPtX0JBI3Xk56PsOXjpoxpRpOyC
D4b8wmKVILyC0SoiuNaT8BYscbln0f4qnMZhYHjSsPxEcbcoEONtIKRWu3mw/ZVDw3K9J7kbKn1W
axoFWmwMJfbKAv/78MFyk2U5S3KfvDeJ+L8EDCHDbxd5Ko0zv6/zYiyjL9TDq2xO0AA2hsRbvcCG
X7n7xE3UXMEuqgvnJ0NHiCSFJyGw06xI4mHFOSpJEvm4rlZJMvStgQLGVgV+lj178JVCfnm8Fw9K
du2G050sytee2gjh+Dgw9HDRdXEnYqRYGwhg6bh7QH8WocEcOpvcERuS5VfykK4KpLsrWvwyya3Z
gUJYLj9JXWwDNGToJL+vGoFWCaqJAi6wnYrQ0IT93ng5rAm4Xl+4kBTQaXY0pc+57NF7TiRWsSSC
RkujetmosY/K4dTQLdLqMm/QRxMGmsZakl4lZ3LKK/IdSlxApUqZzaPACA8XBo0VidGpFhQ3n4wk
qo7PEnmSg420Jbl7Sn35SFteU1FvIew9s93N0+cF+lM8kvxgnsRGW1EFMrZ07JGlUhy36gZb7jgw
woI0Bbe6eENXjg4dxNSfoOFnWYawoNN+RfYJpHEZ+Z2O2gRd5qP8k8SGrdoKPBiU1105RHgTEJ5S
Wy+kzKPAyBIZxN2MFBe02gh+s312ZDg+i03S5skmSHLXuZH0wkgQ8VQIe8s+RvKLhq4P5Fd14S6y
YzQEjt0i2TOCrbpsjqgRwAe8uXuY7+OuAzeggnjjzLkjWVyMtvpAvbYkkSNqd5AnuMtGYRQOQDkj
Zk0hvFbTae60mkWQV8ZKKSpc+qG1zM58BxZVXEArVz+CiHFMijsFGD5L7NgYTciANJJWSe9+EyPY
PgMVUt+u3yqxfFG4JqGavnbkvf22hVTkS1kXb23HW0hEUWYLVvIYszN/dUmiylFN9dCJdj8pFGVG
mjBaHctmdTcyumN9i2c23M0bIAAAAJlJREFUnuspfTEl7niQ/jITd27K2mrtZLIDsxSTHBxZg5O0
SnrLIeZ7Q4Pgleu39iuHuijq5bDhulEztuUHRqqNGlDbW3ObujBlFXobe2tcPpPLPdxj3m20f7Fd
dhn3FOknuEZ75E2oxHexcmYUnQQMPJ3ofqf+KRFjnHPyCdDIsMH0swou0uEhrBBkvhH9oiHgIJY9
ye0f6n+DU6QMfYvVvgAAAABJRU5ErkJggg==
"
       id="image72" />
  </g>
</svg>
`]

// export const sygnet = ['160 160', `
//   <title>coreui logo</title>
//   <g>
//     <g style="fill:#fff;">
//       <path d="M125,47.091,86,24.5743a12,12,0,0,0-12,0L35,47.091a12.0336,12.0336,0,0,0-6,10.3923v45.0334a12.0335,12.0335,0,0,0,6,10.3923l39,22.5166a11.9993,11.9993,0,0,0,12,0l39-22.5166a12.0335,12.0335,0,0,0,6-10.3923V57.4833A12.0336,12.0336,0,0,0,125,47.091Zm-2,55.4257a4,4,0,0,1-2,3.464L82,128.4974a4,4,0,0,1-4,0L39,105.9807a4,4,0,0,1-2-3.464V57.4833a4,4,0,0,1,2-3.4641L78,31.5025a4,4,0,0,1,4,0l39,22.5167a4,4,0,0,1,2,3.4641Z"/>
//       <path d="M103.0216,93.0379h-2.866a4,4,0,0,0-1.9246.4935L80.95,103.0167,61,91.4981V68.5206L80.95,57.002l17.2894,9.455a4,4,0,0,0,1.9192.4905h2.8632a2,2,0,0,0,2-2V62.2357a2,2,0,0,0-1.04-1.7547L84.793,49.9854a8.0391,8.0391,0,0,0-7.8428.09L57,61.5929A8.0243,8.0243,0,0,0,53,68.5216v22.976a8,8,0,0,0,4,6.9283l19.95,11.5185a8.0422,8.0422,0,0,0,7.8433.0879l19.19-10.5311a2,2,0,0,0,1.0378-1.7534v-2.71A2,2,0,0,0,103.0216,93.0379Z"/>
//     </g>
//   </g>
// `]
