import React, { useContext } from 'react'
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
  CCol,
} from '@coreui/react'

import { ToastContext } from 'contexts/Toast'

import './index.scss'

// posições possíveis para toast // autohideValue = null toast fica fixo até clicar no x
// 'static', // fixo dentro da página para isso importa o default Toast dentro do elemento e tira do index
// 'top-left'
// 'top-center'
// 'top-right'
// 'top-full'
// 'bottom-left'
// 'bottom-center'
// 'bottom-right'
// 'bottom-full'

// sempre que alterado o state é mostrado um alert, a valor position sempre deve ser passado o resto é opcional
const Toast = () => {
    const { toasts } = useContext(ToastContext);

    const toasters = (() => {
        return toasts.reduce((toasters, toast) => {
            toasters[toast.position] = toasters[toast.position] || []
            toasters[toast.position].push(toast)
            return toasters
        }, {})
    })()

    return (
        <CCol sm="12" lg="6">
            {Object.keys(toasters).map((toasterKey) => (
                <CToaster
                    position={toasterKey}
                    key={'toaster' + toasterKey}
                >
                    {toasters[toasterKey].map((toast, key) => {
                        return(
                            <CToast
                                key={'toast' + key}
                                show={true}
                                autohide={toast.autohide ? toast.autohide : 5000}
                                fade={toast.fade === 'remove' ? false : true}
                            >
                                <CToastHeader 
                                    closeButton={toast.closeButton === 'remove' ? false : true}
                                    className={`toast-header-${toast.type ? toast.type : 'success'}`}
                                >
                                    {toast.title ? toast.title : 'Mensagem'}
                                </CToastHeader>
                                
                                <CToastBody className={`toast-body-${toast.type ? toast.type : 'success'}`}>
                                    {`${toast.message ? toast.message : ''}`}
                                </CToastBody>
                            </CToast>
                        )
                    })}
                </CToaster>
            ))}
        </CCol>
    )
}

export default Toast
