import React, { useState, useContext } from 'react'
import { SpinnerReactContext } from 'contexts/SpinnerReact'
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  background-color: #61dafb;
`;

const SpinnerReact = () => {
    const { spinnerReact } = useContext(SpinnerReactContext)
    const [color] = useState("#9B111E")

    return (
        <div className={spinnerReact ? 'react-spinner' : 'display-none'}>
            <div className="sweet-loading">
                <PropagateLoader 
                    color={color} 
                    loading={spinnerReact} 
                    size={40} 
                    css={override} 
                />
            </div>
        </div>
    )
}

export default SpinnerReact
