import React, { useState, createContext, useContext } from "react"

export const AuthenticatedContext = createContext()

export default function AuthenticatedProvider({ children }) {
  const [authenticated, setAuthenticated] = useState({})

  return (
    <AuthenticatedContext.Provider value={{ authenticated, setAuthenticated }}>
      { children }
    </AuthenticatedContext.Provider>
  )
}

export function useAuthenticated() {
  const { authenticated, setAuthenticated } = useContext(AuthenticatedContext)

  return { authenticated, setAuthenticated }
}