import React, { useState, createContext, useContext } from "react"

export const ModalAlertContext = createContext()

export default function ModalAlertProvider({ children }) {
  const [modal, setModal] = useState({})

  return (
    <ModalAlertContext.Provider value={{ modal, setModal }}>
      { children }
    </ModalAlertContext.Provider>
  )
}

export function useModalAlert() {
  const { modal, setModal } = useContext(ModalAlertContext)

  return { modal, setModal }
}